.Header {
  background-color: #343028;
  height: var(--header-height);
  padding: 26px 56px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;

  .logo {
    position: absolute;
    left: 69px;
    top: 50%;
    transform: translateY(-50%);
  }

  .navbar {
    margin: 0 auto;
  }
}