:root {

  --font-family-main: 'Manrope', sans-serif;

  --header-height: 84px;

  --font-size-s: 14px;
  --font-size-m: 16px;
  --font-size-l: 24px;

  --default-dark: #343028;
  --default-white: #FFF;
  --accent-green-dark: #CBE95F;
  --accent-orange: #FAA62D;
  --default-back: #F6F5ED;

}