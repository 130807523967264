.Button {
  border: none;
  background: none;
  transition: all .2 linear;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}

.disabled {
  opacity: 0.5;
  &:hover {
    cursor: not-allowed;
  }
}

.fullWidth {
  width: 100%;
}