.SignOut {
  position: absolute;
  right: 56px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    opacity: 0.5;
    margin-right: 8px;
  }
}